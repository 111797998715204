import React, { createContext } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface ContextProps {
  title: string | undefined
}

export const VacancyContext = createContext<ContextProps>({
  title: '',
})

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
      services: {
        nodes: []
      }
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo, title },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <VacancyContext.Provider
      value={{
        title,
      }}
    >
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </VacancyContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
